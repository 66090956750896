<style scoped>
.drag-area {
  min-height: 60px;
}

.display-none {
  display: none;
}

.fix-height {
  height: calc(100vh - theme('spacing[84]') - theme('spacing[10]'))
}

.fix-height-full {
  height: calc(100vh - theme('spacing[16]'))
}

</style>
<template>
  <div :class="{ blur: isLoadingFilter }">
    <div class="w-full">
      <div v-if="isLoading && !editChart" class="z-50 absolute w-full h-full cursor-wait backdrop-filter backdrop-blur-sm"></div>
      <div v-if="!isLoading && !checkPermissionAdmin" class="text-center text-xl mt-20">
        {{$t('Ungültige Berechtigungen: Für Ihre Klinik liegen keine Daten vor.')}}<br/>
        {{$t('Bitte kontaktieren Sie ihren Administrator.')}}
      </div>
      <div v-else>

        <EditChartFSP
          class="z-50"
          v-if="editChart && restrictToFspAnq == true"
          :chart="editChart"
          :userChart="editUserCharts"
          :measures="item.measures"
          :dimensionsInit="seriesDimensions"
          :pickTranslationFromJson="pickTranslationFromJson"
          :language="currentEvaluationLang"
          @on-edit-chart="onEditChart"
          @on-cancel="onCancelEditChart"
          @on-update-chart="onUpdateChart"
          @on-update-userChart="onUpdateUserChart"
          @on-measure-changed="onMeasureChanged"
          @on-filter-changed="onFilterChanged"
        />

        <edit-chart
          class="z-50"
          v-if="editChart && restrictToFspAnq == false"
          :chart="editChart"
          :userChart="editUserCharts"
          :measures="item.measures"
          :dimensionsInit="seriesDimensions"
          :language="getLanguage"
          @on-cancel="onCancelEditChart"
          @on-update-chart="onUpdateChart"
          @on-update-userChart="onUpdateUserChart"
          @on-measure-changed="onMeasureChanged"
          @on-filter-changed="onFilterChanged"
        />
        <div class="relative left-0 flex-row w-full z-10 pointer-events-none bg-white dark:bg-neutral-800">
          <div class="pointer-events-auto">
            <div v-if="readOnlyView === false" class="relative flex justify-between w-full pb-2 border-b-2 dark:border-neutral-600">
              <div class="flex gap-2">
                <div
                  v-if="isAdminOrStaff || charts.length"
                  @click="currentMainTab=1"
                  class="flex items-center rounded-full px-3 py-1 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-900 whitespace-nowrap"
                  :class="{'bg-secondary font-bold text-white hover:bg-secondary hover:text-white': currentMainTab===1, 'bg-benjamin-anq hover:bg-benjamin-anq': currentMainTab===1 && restrictToAnqOnly == true }"
                >
                  {{ pickTranslationFromJson(currentEvaluationLang, 'evaluation') }}
                </div>
                <div
                  v-if="this.hasTextAnswers && (isAdminOrStaff || charts.length)"
                  @click="currentMainTab=2"
                  class="flex items-center rounded-full px-3 py-1 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-900 whitespace-nowrap"
                  :class="{'bg-secondary font-bold text-white hover:bg-secondary hover:text-white': currentMainTab===2, 'bg-benjamin-anq hover:bg-benjamin-anq': currentMainTab===2 && restrictToAnqOnly == true }"
                >
                  {{ pickTranslationFromJson(currentEvaluationLang, 'text-answers') }}
                </div>
                <div
                  v-if="isAdminOrStaff"
                  @click="currentMainTab=3"
                  class="flex items-center rounded-full px-3 py-1 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-900 whitespace-nowrap"
                  :class="{'bg-secondary font-bold text-white hover:bg-secondary hover:text-white': currentMainTab===3, 'bg-benjamin-anq hover:bg-benjamin-anq': currentMainTab===3 && restrictToAnqOnly == true }"
                >
                  {{ pickTranslationFromJson(currentEvaluationLang, 'raw-data') }}
                </div>
                <div
                  v-if="isAdminOrStaff"
                  @click="currentMainTab=4"
                  class="flex items-center rounded-full px-3 py-1 text-sm cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-900 whitespace-nowrap"
                  :class="{'bg-secondary font-bold text-white hover:bg-secondary hover:text-white': currentMainTab===4, 'bg-benjamin-anq hover:bg-benjamin-anq': currentMainTab===4 && restrictToAnqOnly == true }"
                >
                  {{ pickTranslationFromJson(currentEvaluationLang, 'settings') }}
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex text-lg mr-3"
                >
                  <!-- this is translation to the tabs in evaluation -->
                  <span
                    v-for="lang in languagesMeasureString"
                    :key="lang"
                    class="inline-block border rounded p-1 cursor-pointer w-10 text-center mr-1"
                    :class="[currentEvaluationLang === lang ? 'border-primary bg-primary dark:bg-neutral-600 text-white' : 'border-white dark:border-neutral-600 dark:bg-neutral-400 dark:hover:bg-neutral-600 hover:border-neutral-200 text-primary hover:bg-neutral-200']"
                    @click="currentEvaluationLang=lang"
                  >
                    {{lang}}
                  </span>
                </div>
                <div
                  class="flex items-center justify-center w-[100px]"
                  v-if="restrictToFspOnly == true || restrictToAnqOnly == true"
                >
                  <img
                    v-if="restrictToFspOnly == true"
                    src="../../assets/images/fsp_logo.png"
                  />
                  <img
                    v-if="restrictToAnqOnly == true"
                    src="../../assets/images/anq_logo.png"
                  />
                </div>
              </div>
            </div>
            <div>
              <div v-if="currentMainTab === 1 && isTabBarOverflowLeft" @click="scrollLeft()" class="absolute left-0 cursor-pointer bg-white h-11 w-10 flex items-center justify-center z-50 border-r">
                <svg class="rotate-180 fill-current text-neutral-600 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
              </div>
              <div v-if="currentMainTab === 1 && isTabBarOverflowRight" @click="scrollRight()" class="absolute right-0 cursor-pointer bg-white h-11 w-16 flex items-center justify-center z-50 border-l">
                <div v-if="isAdminOrStaff" class="text-secondary hover:text-neutral-500 cursor-pointer ml-1" @click="addTab()">
                  <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"/></svg>
                </div>
                <svg class="fill-current text-neutral-600 w-4 ml-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z"/></svg>
              </div>
              <div class="relative border-b-2 h-11 w-full dark:border-neutral-600">
                <div @click="updateScrollTabbar()" ref="tabbar" class="absolute flex w-full pt-2 px-2 h-11 scrollbar-hide overflow-x-auto " v-if="currentMainTab===1">
                  <div class="absolute flex items-center pr-20">
                    <draggable
                      :list="tabs"
                      class="flex"
                      group="Tabs"
                      @change="updateTabs">
                      <div v-for="(tab, index) in tabs" :key="index">
                        <div
                          class="w-40 min-w-min relative flex items-center border-t-2 border-r-2 border-l-2 px-2 h-8 text-xs cursor-pointer mr-1 dark:border-neutral-500"
                          :class="[currentTab === index ? 'font-bold h-9 bg-white dark:bg-neutral-800 border-b-2 border-b-white dark:border-b-neutral-800' : 'bg-neutral-50 dark:bg-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-700']"
                          @click="tabClick($event, index)"
                        >
                          <!-- that is value That I need to send to the beckend for translation -->
                          <span
                            class="mr-5 w-full overflow-hidden whitespace-nowrap"
                            :class="{'truncate' : currentEditTab !== index}"
                            @paste.prevent="isAdminOrStaff ? onPasteTab : null"
                            :contenteditable="currentEditTab === index && isAdminOrStaff"
                            @blur="updateTab"
                            @keydown="checkEndEdit"
                          >
                            {{ translate(tab, 'name', currentEvaluationLang ? currentEvaluationLang : getLanguage) }}
                          </span>

                          <div v-if="isAdminOrStaff && tabs.length > 1" class="absolute right-1 rounded-full hover:bg-neutral-400 p-1"  @click="removeTab(index)">
                            <svg class="text-neutral-600 fill-current h-2 w-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                          </div>
                        </div>
                      </div>
                    </draggable>
                    <div v-if="!isTabBarOverflowRight && isAdminOrStaff" class="text-secondary hover:text-neutral-500 cursor-pointer ml-1" @click="addTab()">
                      <svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"/></svg>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <portal to="toolbar">
              <div v-if="currentMainTab === 1" class="flex gap-1 border-r-2 dark:border-neutral-600 py-1 mr-3 pr-3">
                <div
                  v-if="!editChart && isAdminOrStaff"
                  @click="addEvaluation()"
                  class="cursor-pointer hover:bg-emerald-800 rounded-full bg-emerald-600 text-add h-10 w-10 text-4xl text-white flex items-center justify-center"
                  v-tooltip="$t('new-chart')"
                >
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 19h-4v-4h4v4zm6 0h-4v-8h4v8zm6 0h-4v-13h4v13zm6 0h-4v-19h4v19zm1 2h-24v2h24v-2z"/></svg>
                </div>
                <div
                  v-if="!editChart && isAdminOrStaff"
                  @click="addTextBlock()"
                  class="cursor-pointer hover:bg-emerald-800 rounded-full bg-emerald-600 text-add h-10 text-sm font-bold px-3 text-white flex items-center justify-center"
                  v-tooltip="$t('new-text')"
                >
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22 0h-20v6h1.999c0-1.174.397-3 2.001-3h4v16.874c0 1.174-.825 2.126-2 2.126h-1v2h9.999v-2h-.999c-1.174 0-2-.952-2-2.126v-16.874h4c1.649 0 2.02 1.826 2.02 3h1.98v-6z"/></svg>
                </div>
                <div
                  v-if="!editChart && canExportPdf"
                  @click="exportPdf()"
                  class="cursor-pointer hover:bg-blue-800 rounded-full bg-blue-600 text-add h-10 w-10 text-4xl text-white flex items-center justify-center"
                  v-tooltip="$t('export-pdf')"
                >
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6 2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473 0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235 0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z"/></svg>
                </div>
              </div>
            </portal>
          </div>
        </div>
        <div>
          <div v-if="currentMainTab === 3 && this.exceedData != true">
            <raw-data-table :fullItems="fullItems()"
                            :rawItems="rawItems()"
                            :rawHeaders="rawHeaders"
                            :measures="item.measures"
                            :language="currentEvaluationLang"/>
          </div>
          <div v-else-if="currentMainTab === 1" class="block mb-96">
            <div v-if="isLoadingFilter && !editChart" class="absolute w-full h-screen z-50 pt-20 text-center cursor-wait">
              <svg xmlns="http://www.w3.org/2000/svg"
                   style="margin:auto;display:block"
                   width="200"
                   height="200"
                   viewBox="0 0 100 100"
                   preserveAspectRatio="xMidYMid"><path d="M40 40h6v6h-6z"><animate attributeName="fill"
                                                                                    values="#9b2c2c;#000000;#000000"
                                                                                    keyTimes="0;0.125;1"
                                                                                    dur="1.8518518518518516s"
                                                                                    repeatCount="indefinite"
                                                                                    begin="0s"
                                                                                    calcMode="discrete"/></path><path d="M47 40h6v6h-6z"><animate attributeName="fill"
                                                                                                                                                  values="#9b2c2c;#000000;#000000"
                                                                                                                                                  keyTimes="0;0.125;1"
                                                                                                                                                  dur="1.8518518518518516s"
                                                                                                                                                  repeatCount="indefinite"
                                                                                                                                                  begin="0.23148148148148145s"
                                                                                                                                                  calcMode="discrete"/></path><path d="M54 40h6v6h-6z"><animate attributeName="fill"
                                                                                                                                                                                                                values="#9b2c2c;#000000;#000000"
                                                                                                                                                                                                                keyTimes="0;0.125;1"
                                                                                                                                                                                                                dur="1.8518518518518516s"
                                                                                                                                                                                                                repeatCount="indefinite"
                                                                                                                                                                                                                begin="0.4629629629629629s"
                                                                                                                                                                                                                calcMode="discrete"/></path><path d="M40 47h6v6h-6z"><animate attributeName="fill"
                                                                                                                                                                                                                                                                              values="#9b2c2c;#000000;#000000"
                                                                                                                                                                                                                                                                              keyTimes="0;0.125;1"
                                                                                                                                                                                                                                                                              dur="1.8518518518518516s"
                                                                                                                                                                                                                                                                              repeatCount="indefinite"
                                                                                                                                                                                                                                                                              begin="1.6203703703703702s"
                                                                                                                                                                                                                                                                              calcMode="discrete"/></path><path d="M54 47h6v6h-6z"><animate attributeName="fill"
                                                                                                                                                                                                                                                                                                                                            values="#9b2c2c;#000000;#000000"
                                                                                                                                                                                                                                                                                                                                            keyTimes="0;0.125;1"
                                                                                                                                                                                                                                                                                                                                            dur="1.8518518518518516s"
                                                                                                                                                                                                                                                                                                                                            repeatCount="indefinite"
                                                                                                                                                                                                                                                                                                                                            begin="0.6944444444444444s"
                                                                                                                                                                                                                                                                                                                                            calcMode="discrete"/></path><path d="M40 54h6v6h-6z"><animate attributeName="fill"
                                                                                                                                                                                                                                                                                                                                                                                                          values="#9b2c2c;#000000;#000000"
                                                                                                                                                                                                                                                                                                                                                                                                          keyTimes="0;0.125;1"
                                                                                                                                                                                                                                                                                                                                                                                                          dur="1.8518518518518516s"
                                                                                                                                                                                                                                                                                                                                                                                                          repeatCount="indefinite"
                                                                                                                                                                                                                                                                                                                                                                                                          begin="1.3888888888888888s"
                                                                                                                                                                                                                                                                                                                                                                                                          calcMode="discrete"/></path><path d="M47 54h6v6h-6z"><animate attributeName="fill"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        values="#9b2c2c;#000000;#000000"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        keyTimes="0;0.125;1"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        dur="1.8518518518518516s"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        repeatCount="indefinite"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        begin="1.1574074074074074s"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        calcMode="discrete"/></path><path d="M54 54h6v6h-6z"><animate attributeName="fill"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      values="#9b2c2c;#000000;#000000"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      keyTimes="0;0.125;1"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      dur="1.8518518518518516s"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      repeatCount="indefinite"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      begin="0.9259259259259258s"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      calcMode="discrete"/></path></svg>
            </div>

            <grid-layout
              class="z-10 grid"
              :key="currentTab"
              ref="grid-layout"
              :layout.sync="chartsForCurrentTab"
              :col-num="colNum"
              :row-height="rowHeight"
              :is-draggable="isAdminOrStaff"
              :is-resizable="isAdminOrStaff"
              :is-mirrored="false"
              :vertical-compact="true"
              :autoSize="true"
              :preventCollision="false"
              :margin="[10, 10]"
              :use-css-transforms="true"
            >
              <grid-item v-for="(item, index) in chartsForCurrentTab"
                         :x="item.x"
                         :y="item.y"
                         :w="item.w"
                         :h="item.h"
                         :i="item.i"
                         :minW="3"
                         :minH="item.type === 'text' ? 1 : 10"
                         :key="`${index}_${item.id}`"
                         @resize="resizeEvent"
                         @resized="resizedEvent"
                         @moved="movedEvent"
                         drag-allow-from=".vue-draggable-handle"
                         drag-ignore-from=".no-drag"
                         :style="{'z-index' : 100+item.y}"
              >
                <Chart
                  :ref="`chart_${item.i}`"
                  :chart="item"
                  :userChart="editUserCharts"
                  :isAdminOrStaff="isAdminOrStaff"
                  :readOnlyView="readOnlyView"
                  :print="print"
                  :hasTabs="tabs.length > 1"
                  :language="currentEvaluationLang"
                  @on-update-chart="onUpdateChart"
                  @on-update-userChart="onUpdateUserChart"
                  @on-edit-chart="onEditChart"
                  @on-clone-chart="onCloneChart"
                  @on-delete-chart="removeEvaluation"
                  @on-move-chart="onMoveChart"
                />
              </grid-item>
            </grid-layout>
          </div>
          <div v-else-if="currentMainTab === 2">
            <EvaluationTxtTable v-if="hasTextAnswers" :headers="rawTxtHeaders" :itemsInit="rawItems()" :measures="item.measures"/>
          </div>
          <div v-else-if="currentMainTab === 4">

            <div v-if="isStaff" class="js-upload">
              <label v-tip="'evaluations'" class="mt-2 text-lg">{{ pickTranslationFromJson(currentEvaluationLang, 'importSettings') }}</label>
              <input type="file"
                     id="json"
                     name="json"
                     class="hidden"
                     ref="file"
                     @change="loadJSON($event)"/>
              <p v-if="fileName" class="uk-display-inline-block uk-width-1-3">{{fileName}}</p>
              <p v-else class="uk-display-inline-block uk-width-1-2">{{ pickTranslationFromJson(currentEvaluationLang, 'NoChosenFile') }}</p>
              <button class="" type="button" tabindex="-1" @click="$refs.file.click()">{{ pickTranslationFromJson(currentEvaluationLang, 'chooseFile')}}</button>
            </div>

            <div class="mt-3">
              <label v-tip="'evaluations'" class="mt-2 text-lg">
                {{ pickTranslationFromJson(currentEvaluationLang, 'name-evaluation')}}
              </label>
              <v-input-languages  v-if="isAdminOrStaff"
                                  :value="editValues"
                                  :labelKey="'name'"
                                  :languages="languagesMeasure"
                                  @input="updateEditValues"/>
              <span v-else>
                {{ pickTranslationFromJson(currentEvaluationLang, 'name')}}
              </span>
            </div>

            <edit-dimensions
              v-if="isStaff"
              :series="allSeriesDefaultGrouped()"
              :initDimensions="editValues.dimensions || []"
              :language="currentEvaluationLang"
              @update="updateDimensions"
            />

            <div class="mt-3" v-if="hasTimelineAttribute">
              <label v-tip="'evaluations'" class="mb-1 mt-2">{{ pickTranslationFromJson(currentEvaluationLang, 'filter-by-days')}}</label>
              <template v-if="isAdminOrStaff">
                <v-checkbox class="mb-1"
                            v-if="editValues.days_back !== -2"
                            :value="editValues.days_back === -1"
                            @input="toggleCheckbox('currentYear')"
                >
                  {{ pickTranslationFromJson(currentEvaluationLang, 'current-year')}}
                </v-checkbox>

                <v-checkbox
                  :value="editValues.days_back === -2"
                  @input="toggleCheckbox('previousYear')"
                >
                  {{ pickTranslationFromJson(currentEvaluationLang, 'previous-year')}}
                </v-checkbox>

                <input
                  v-if="editValues.days_back !== -1 && editValues.days_back !== -2"
                  class="mt-2"
                  id="daysbackInput"
                  :min="0"
                  :max='1096'
                  :maxlength='4'
                  type="number"
                  v-model.number="editValues.days_back"
                />
                <div v-if="this.editValues.measures.length > 1" class="mb-2">
                  <label>{{ $t('results.measure') }}</label>
                  <v-select
                    v-model.lazy="selected"
                    :options="getFilteredOptions()"
                    :mandatory="true"
                    @input="onElementChanged"
                  />
                </div>
              </template>
              <div v-else>
                <span v-if="editValues.days_back === -1 && editValues.days_back !== -2">
                  {{ pickTranslationFromJson(currentEvaluationLang, 'current-year')  }}
                </span>
                <span v-else>
                  {{editValues.days_back}} {{ pickTranslationFromJson(currentEvaluationLang, 'days')  }}
                </span>
              </div>
            </div>
            <div class="mt-2">
              <label v-tip="'evaluations'" class="mt-4">{{ pickTranslationFromJson(currentEvaluationLang, 'filter-global') }}</label>
              <div v-if="this.editValues.measures.length > 1" class="mb-2">
                <label>{{ $t('results.measure') }}</label>
                <v-select v-model="measureGlobalFilter" :options="measureSelect.filter(m => this.editValues.measures.indexOf(m.id) > -1)" :mandatory="true"/>
              </div>
              <evaluation-filter
                :series="seriesDefaultGrouped(measureGlobalFilter)"
                :language="currentEvaluationLang"
                :pickTranslationFromJson="pickTranslationFromJson"
                :filters="editValues.filters"
                :measure="measureGlobalFilter"
                :hasMultipleMeasures="this.editValues.measures.length > 1"
                :isGlobalFilter="true"
                @on-filter-changed="onFilterGlobalChanged"
              />
            </div>
            <div class="mt-2" v-if="!user.is_demo">
              <!-- <label v-tip="'evaluations'" class="mt-4">{{ $t('measures') }}</label> -->
              <label v-tip="'evaluations'" class="mt-4">{{ pickTranslationFromJson(currentEvaluationLang, 'measures') }}</label>
              <v-select v-if="isStaff"
                        v-model="editValues.measures"
                        :options="measureSelect"
                        @changed="measureSelected"
                        :multiselect="true"/>
              <div v-else>
                <ul class="list-disc">
                  <li v-for="m in editValues.measures" :key="m" class="ml-6">
                    {{ measureById(m).key}}
                  </li>
                </ul>
              </div>

              <label v-tip="'evaluations'" class="mt-4">{{ pickTranslationFromJson(currentEvaluationLang, 'admins') }}</label>
              <relation-select
                v-model="editValues.users_admin"
                :isReadOnly="!isAdminOrStaff"
                :language="currentEvaluationLang"
                :pickTranslationFromJson="pickTranslationFromJson"
                :getterPath="'accounts/users'"
                :query="{ measures: measuresIds }"
                :isMulti="true"
                :noSizeParams="true"
                @input="updateEditValues"
              />

              <label v-tip="'evaluations'" class="mt-4">{{ pickTranslationFromJson(currentEvaluationLang, 'view-permissions-units') }}</label>
              <custom-select
                :searchable="true"
                v-model="editValues.view_access_object_ids"
                :language="currentEvaluationLang"
                :pickTranslationFromJson="pickTranslationFromJson"
                :isReadOnly="!isAdminOrStaff"
                :itemsraw="allViewAccessObjects"
                :fields="setColumnsinTable"
                @input="updateEditValues"
              />
              <template v-if="isStaff">
                <button v-tip="'evaluations'" @click="download_evaluation()" class="primary mt-8 mb-6 w-full">{{ pickTranslationFromJson(currentEvaluationLang, 'download-settings') }}</button>
              </template>
            </div>
          </div>
        </div>
        <TabMover :visible="tabMoverVisible"
                  :chart="moveChart"
                  :tabs="tabs"
                  @on-tab-selected="onTabSelected"
                  @cancel="moveChart=null; tabMoverVisible=false"/>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import draggable from 'vuedraggable'
import { DATE_FORMATS } from '@/constants.js'
import '@interactjs/auto-start'
import '@interactjs/actions/drag'
import '@interactjs/actions/resize'
import '@interactjs/modifiers'
import '@interactjs/dev-tools'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { clone } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import Chart from '@/components/charts/Chart'
import utilsMixin from '@/mixins/utils'
import arrayToCsvMixin from '@/mixins/arrayToCsv'
import ApiClient from '@/assets/js/ApiClient.js'
import EvaluationTxtTable from '@/components/display/evaluations/EvaluationTxtTable.vue'
import EditDimensions from '@/components/display/evaluations/EditDimensions.vue'
import dateMixin from '@/mixins/date'
import dayjs from 'dayjs'
import VueGridLayout from 'vue-grid-layout'
import EditChart from '@/components/charts/EditChart.vue'
import EditChartFSP from '@/components/chartsFSP/EditChartFSP.vue'
import TabMover from '@/components/charts/TabMover'
import RelationSelect from '@/components/RelationSelect.vue'
import CustomSelect from '@/components/CustomSelect.vue'
import RawDataTable from '@/components/charts/RawDataTable.vue'
import VSelect from '@/components/v-select'
import VCheckbox from '@/components/v-checkbox'
import EvaluationFilter from '@/components/display/evaluations/EvaluationFilter.vue'
import VInputLanguages from '@/components/v-input-languages'
import deJson from '../../assets/lang/translations/de.json'
import frJson from '../../assets/lang/translations/fr.json'
import itJson from '../../assets/lang/translations/it.json'

let customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
let isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

export default {
	mixins: [utilsMixin, dateMixin, arrayToCsvMixin],
	name: 'EditEvaluation',
	components: {
		draggable,
		Chart,
		GridLayout: VueGridLayout.GridLayout,
		GridItem: VueGridLayout.GridItem,
		RawDataTable,
		EditChart,
		EditChartFSP,
		EvaluationTxtTable,
		TabMover,
		RelationSelect,
		VSelect,
		VCheckbox,
		CustomSelect,
		EditDimensions,
		EvaluationFilter,
		VInputLanguages
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		chartUserArray: {
			type: Object,
			required: true
		},
		readOnlyView: {
			type: Boolean,
			default: false
		},
		resultDataInit: {
			type: Array,
			default: () => null
		}
	},
	data: function () {
		return {
			resizing: false,
			print: false,
			editValues: clone(this.item),
			editUserCharts: clone(this.chartUserArray),
			charts: [],
			currentChartIndex: 0,
			openIndex: -1,
			showTop: true,
			currentMainTab: 1,
			loading: 0,
			editChart: null,
			colNum: 24,
			rowHeight: 30,
			tabs: [],
			currentTab: 0,
			currentEditTab: -1,
			tabMoverVisible: false,
			moveChart: null,
			currMeasure: undefined,
			allViewAccessObjects: [],
			seriesDimensions: [],
			loadedData: {},
			showLeftScroll: false,
			showRightScroll: false,
			measureGlobalFilter: null,
			currentEvaluationLang: null,
			debouncedReload: null,
			selected: 'all',
			oldSelected: 'all',
			db: null,
			fileName: '',
			isLoadingFilter: false
		}
	},
	computed: {
		...mapGetters({
			user: 'user',
			getEvaluationById: 'results/evaluations/getById',
			appPermissions: 'accounts/appPermissions/all',
			measureById: 'results/measures/getById',
			measureSelect: 'results/measures/forSelection',
			fields: 'results/measures/fields',
			isLoading: 'results/evaluationStore/isLoading',
			resultData: 'results/evaluationStore/resultData',
			seriesDefaultGrouped: 'results/evaluationStore/seriesDefaultGrouped',
			seriesDefaultWithoutText: 'results/evaluationStore/seriesDefaultWithoutText',
			seriesDefault: 'results/evaluationStore/seriesDefault',
			languagesMeasure: 'results/evaluationStore/languages',
			languagesMeasureString: 'results/evaluationStore/languagesString',
			currentEvaluation: 'results/evaluations/detailItem',
			exceedData: 'results/evaluationStore/exceedData'
		}),
		isInEvaluationOnly () {
			return this.$route.path.startsWith('/results/evaluations/')
		},
		setColumnsinTable () {
			const baseArray = [{ text: 'Name', value: 'name', width: -1 }]
			if (this.restrictToMoniqOnly) {
				baseArray.push({ text: 'Kategorie', value: 'category_text', width: 200 }, { text: 'ANQ-Id', value: 'anqid', width: 80 }, { format: 'BooleanView', text: 'Dispensiert', textHeader: 'Disp.', type: 'boolean', value: 'is_dispensed', width: 60 })
			}

			return baseArray
		},
		extendedMeasureOptions () {
			const allMeasuresOption = { id: 'all', value: 'All Measures' }
			return [allMeasuresOption, ...this.measureSelect]
		},
		restrictToMoniqOnly () {
			return this.currentEvaluation?.content_type === 14
		},
		restrictToFspOnly () {
			const current = this.currentEvaluation?.measures
			return current.includes(65) || current.includes(66) || current.includes(101)
		},
		restrictToAnqOnly () {
			const current = this.currentEvaluation.measures
			return current.includes(113) || current.includes(61) || current.includes(62) || current.includes(63)
		},
		restrictToFspAnq () {
			const current = this.currentEvaluation.measures
			return current.includes(65) || current.includes(66) || current.includes(101) || current.includes(113) || current.includes(61) || current.includes(62) || current.includes(63)
		},
		checkPermissionAdmin () {
			// Ein Administrator muss auch alle Berechtigungen zu den Messungen bzw Gruppen haben welche in dieser Messung definiert wurden
			if (this.isAdmin && !this.user.is_staff) {
				for (const mId of this.editValues.measures) {
					const measure = this.measureById(mId)
					if (measure === undefined) {
						return false
					}
				}
				return true
			}
			return true
		},
		measuresIds () {
			return this.editValues.measures.map(v => v)
		},
		getLanguage () {
			if (this.language) {
				return this.language
			}
			if (!this.user) {
				return 'de'
			}
			if (this.user.language === 2) {
				return 'fr'
			}
			if (this.user.language === 3) {
				return 'it'
			}
			return 'de'
		},
		isResizing () {
			return this.resizing
		},
		daysBack () {
			return this.editValues.days_back
		},
		hasTimelineAttribute () {
			for (const mId of this.editValues.measures) {
				const measure = this.measureById(mId)
				let hasAttribute = measure.default_attributes.filter(attr => attr.data.filter(d => d.type === 'date_range').length > 0).length > 0
				if (hasAttribute) {
					return true
				}
			}
			return false
		},
		getTimeLineAttribute () {
			for (const mId of this.editValues.measures) {
				const measure = this.measureById(mId)
				if (measure !== undefined) {
					for (const datt of measure.default_attributes) {
						let attr = datt.data.find(d => d.type === 'date_range')
						if (attr) {
							return attr
						}
					}
				}
			}
			return undefined
		},
		canExportPdf () {
			return false
		},
		chartsForCurrentTab () {
			return this.charts.filter(chart => chart.tabIndex === this.currentTab)
		},
		rawHeaders () {
			let result = {}
			for (const mId of this.editValues.measures) {
				let getResultData = this.$globalData.results.evaluationStore.resultData[mId]

				if (!getResultData || getResultData.length === 0) {
					result[mId] = []
				} else {
					result[mId] = Object.keys(getResultData[0].attributes).map(v => {
						let label
						const measure = this.measureById(mId)
						for (const ix in measure.default_attributes) {
							const group = measure.default_attributes[ix]
							label = group.data.find(d => d.name_new === v)
							if (label) {
								break
							}
						}
						const renderItemFunc = (value) => {
							if (Array.isArray(value)) {
								return value.join(', ')
							}
							return value != null ? value : ''
						}
						if (label === undefined) {
							return { text: v.toUpperCase(), value: v, width: 100, type: null, render_to_text: false, renderItem: renderItemFunc }
						}
						return { text: label.label, value: v, width: 100, type: label.type, render_to_text: label.render_to_text, render_text_filter_attribute: label.render_text_filter_attribute, renderItem: renderItemFunc }
					})
				}
			}
			return result
		},
		hasTextAnswers () {
			let getSeries = Object.values(this.seriesDefault).flat()
			return getSeries.some(item => item.type == 'text')
		},
		rawTxtHeaders () {
			let result = {}
			for (const mId of this.editValues.measures) {
				result[mId] = this.rawHeaders[mId].filter(h => h.type === 'text' || h.render_to_text === true)
			}
			return result
		},
		renderCharts () {
			return this.charts.filter(d => d.data && d.data.length)
		},
		isOwner () {
			return (this.currentEvaluation || this.evaluation)?.creator_id ? (this.currentEvaluation || this.evaluation)?.creator_id === this.user.id : false
		},
		evaluation () {
			return this.getEvaluationById(this.$route.params.primaryKey)
		},
		currentChart () {
			return this.charts[this.currentChartIndex]
		},
		measure () {
			return this.currMeasure ? this.currMeasure : this.measureById(this.editValues.measures[0])
		},
		isAdminOrStaff () {
			return (this.isOwner || this.isAdmin || this.user.is_staff === true) && this.readOnlyView === false
		},
		isOwnerOrStaff () {
			return (this.isOwner || this.user.is_staff === true) && this.readOnlyView === false
		},
		isStaff () {
			return this.user.is_staff === true
		},
		isAdmin () {
			return this.editValues.users_admin.includes(this.user.id) || (this.editValues.view_access_admins && this.editValues.view_access_admins.includes(this.user.id))
		},
		isTabBarOverflowRight () {
			return this.showRightScroll
		},
		isTabBarOverflowLeft () {
			return this.showLeftScroll
		},
		isDevOnly () {
			return (process.env.NODE_ENV === 'development') && process.env.VUE_APP_PREFIX === 'local'
		}
	},
	methods: {
		...mapActions({
			reset: 'results/evaluationStore/reset',
			loadData: 'results/evaluationStore/loadData',
			loadSeriesDefault: 'results/evaluationStore/loadSeriesDefault',
			startLoading: 'results/evaluationStore/startLoading',
			endLoading: 'results/evaluationStore/endLoading',
			loadDataByFilter: 'results/evaluationStore/loadDataByFilter',
			filterDataByGlobalFilter: 'results/evaluationStore/filterDataByGlobalFilter'
		}),
		async fetchBenchmarkData (measure) {
			try {
				const response = await ApiClient.get(`results/benchmarks/?ids=${measure.benchmarks.join(',')}`)
				this.$store.commit('results/evaluationStore/setResultBenchmarkData', { measureId: measure.id, data: response.data })
			} catch (error) {
				console.error('err', error)
			}
		},
		async fetchTooltipData (category) {
			try {
				const response = await fetch(`https://docs-tooltips.sos-ch-dk-2.exo.io/${category}-v0.json`)
				this.$store.commit(`results/${category}/storeToolTip`, await response.json())
			} catch (error) {
				console.error('err', error)
			}
		},
		pickTranslationFromJson (currentLanguage, word) {
			const wordArray = word?.split('.')
			let currentValue = deJson

			if (currentLanguage === 'de') {
				currentValue = deJson
			} else if (currentLanguage === 'fr') {
				currentValue = frJson
			} else if (currentLanguage === 'it') {
				currentValue = itJson
			} else {
				return word
			}

			function pickTranslation () {
				for (const key of wordArray) {
					currentValue = currentValue[key]
				}

				return currentValue
			}

			return pickTranslation()
		},
		fullItems () {
			let result = {}
			for (const measure of this.editValues.measures) {
				let getFullData = this.$globalData.results.evaluationStore.fullData[measure]
				if (getFullData === undefined) {
					result[measure] = []
				} else {
					let items = getFullData.map(d => d.attributes)
					// ugly: momentan wenn ein datum vorhanden fix nach datum sortieren
					if (items && items.length && Object.keys(items[0]).indexOf('datum') > -1) {
						items = items.sort((a, b) => dayjs(a.datum, DATE_FORMATS).isAfter(dayjs(b.datum, DATE_FORMATS)) ? -1 : 1)
					}
					result[measure] = items
				}
			}
			return result
		},
		rawItems () {
			let result = {}
			for (const measure of this.editValues.measures) {
				let getResultData = this.$globalData.results.evaluationStore.resultData[measure]
				if (getResultData === undefined) {
					result[measure] = []
				} else {
					let items = getResultData.map(d => d.attributes)
					// ugly: momentan wenn ein datum vorhanden fix nach datum sortieren
					if (items && items.length && Object.keys(items[0]).indexOf('datum') > -1) {
						items = items.sort((a, b) => dayjs(a.datum, DATE_FORMATS).isAfter(dayjs(b.datum, DATE_FORMATS)) ? -1 : 1)
					}
					result[measure] = items
				}
			}
			return result
		},
		download_evaluation () {
			const data = this.editValues
			const serialize = JSON.stringify(data)
			const fileURL = window.URL.createObjectURL(new Blob([serialize]))
			const fileLink = document.createElement('a')
			fileLink.href = fileURL
			const evalID = String(this.editValues.id)
			fileLink.setAttribute('download', 'settings_evaluation_' + evalID + '.json')
			document.body.appendChild(fileLink)
			fileLink.click()
		},
		loadJSON (e) {
			let vm = this
			if (window.FileReader) {
				if (e.target.files[0]) {
					let reader = new FileReader()
					this.fileName = e.target.files[0].name
					reader.readAsText(e.target.files[0])
					// Handle errors load
					reader.onload = function (event) {
						let json = event.target.result
						vm.uploadJSON(json)
					}
					reader.onerror = function (evt) {
						if (evt.target.error.name === 'NotReadableError') {
							alert("Canno't .read file !")
						}
					}
				} else {
					this.fileName = ''
				}
			} else {
				alert('FileReader are not supported in this browser.')
			}
		},
		uploadJSON (json) {
			this.editValues = JSON.parse(json)
			this.updateEditValues()
			this.$root.$emit('usersAdminChanged', this.editValues.users_admin)
		},
		toggleCheckbox (checkboxType) {
			if (checkboxType === 'currentYear') {
				this.editValues.days_back = this.editValues.days_back === -1 ? null : -1
			} else if (checkboxType === 'previousYear') {
				this.editValues.days_back = this.editValues.days_back === -2 ? null : -2
			}
			this.updateEditValues()
		},
		parser (data) {
			const parsedData = []
			for (const key in data) {
				const item = data[key]
				parsedData.push({
					id: key,
					data: item
				})
			}
			return parsedData
		},
		unparser (parsedData) {
			const originalData = {}
			parsedData.forEach((item) => {
				originalData[item.id] = item.data
			})
			return originalData
		},
		openDatabase () {
			const dbName = 'myDatabase'
			const dbVersion = 1
			const request = indexedDB.open(dbName, dbVersion)

			request.onupgradeneeded = (event) => {
				// This will run if it's the first time opening or version has changed
				/* */const db = event.target.result
				db.createObjectStore('myObjectStore', {
					keyPath: 'id',
					autoIncrement: true
				}) // Assuming 'id' as my primary key
			}

			request.onsuccess = (event) => {
				this.db = event.target.result
			}

			request.onerror = (event) => {
				console.error('err', event)
			}
		},
		getData (cachekey, Store = null) {
			if (!this.db) return
			let objectStore = Store
			if (!Store) {
				const transaction = this.db.transaction('myObjectStore')
				objectStore = transaction.objectStore('myObjectStore')
			}
			const key = cachekey.toString()
			const request = objectStore.get(key)
			return new Promise((resolve) => {
				request.onsuccess = (event) => {
					let Data = null
					if (event.target.result) {
						Data = JSON.parse(event.target.result.data)
					}
					resolve(Data)
				}
			})
		},
		async addData (data, daysback) {
			if (!this.isDevOnly) {
				try {
					if (!this.db) return
					// Use the parser function to parse the data and store it in the object store.
					const timestamps = []
					const parsedData = this.parser(data)
					for (const item of parsedData) {
						let response = await ApiClient.get(`results/measures/${item.id}/`)
						const lastImport = response?.data.last_import_at
						timestamps.push({ id: item.id, timestamp: lastImport })
					}
					const transaction = this.db.transaction(['myObjectStore'], 'readwrite')
					const objectStore = transaction.objectStore('myObjectStore')
					for (const item of parsedData) {
						const cachekey = `${item.id}_${daysback}`
						let isCachekeyExist = await this.getData(cachekey, objectStore)
						if ((!isCachekeyExist)) {
							let lastImport = new Date()
							lastImport = timestamps.find(i => i.id === item.id)
							/* */const request = objectStore.add({ id: cachekey, data: JSON.stringify(item.data), lastImport: lastImport.timestamp })
							request.onsuccess = () => {
								console.log('success')
							}

							request.onerror = (event) => {
								console.error('err', event.target.error)
							}
						}
					}
				} catch (e) {
					console.log('error', e)
				}
			}
		},
		async cleanupOldData () {
			if (!this.db) return
			let response = await ApiClient.get('results/measures/')
			const idAndNameList = response.data.map(item => ({ id: item.id, lastImport: item.last_import_at }))
			const transaction = this.db.transaction(['myObjectStore'], 'readwrite')
			const objectStore = transaction.objectStore('myObjectStore')

			// Open a cursor to iterate over all records in the object store
			const cursorRequest = objectStore.openCursor()

			cursorRequest.onsuccess = async (event) => {
				const cursor = event.target.result
				if (cursor) {
					let lastImport = null
					let key = Number(cursor.key.split('_', 1)[0])
					const lastImportCheck = idAndNameList.find(i => i.id === key) || ''
					lastImport = lastImportCheck.lastImport
					// Check if a last import of the data occured after the current import date
					if (cursor.value.lastImport && (lastImport > cursor.value.lastImport)) {
						// Delete the record if it stale
						objectStore.delete(cursor.primaryKey)
					}
					// Move to the next record
					cursor.continue()
				}
			}

			cursorRequest.onerror = (event) => {
				console.error('Error cleaning up old data:', event.target.error)
			}
		},
		getFilteredOptions () {
			const allMeasuresOption = { id: 'all', value: 'Alle Messungen' }

			// Check if 'all' is in editValues.measures
			const allMeasuresSelected = this.editValues.measures.indexOf('all') > -1

			// Filter measureSelect based on editValues.measures
			const filteredOptions = this.measureSelect.filter((m) =>
				allMeasuresSelected ? true : this.editValues.measures.indexOf(m.id) > -1
			)

			// Add 'All Measures' option if not already present
			if (!allMeasuresSelected) {
				return [allMeasuresOption, ...filteredOptions]
			}

			return filteredOptions
		},
		async onFilterGlobalChanged (filters) {
			this.isLoadingFilter = true
			try {
				this.$set(this.editValues, 'filters', filters)
				this.updateEditValues()
				await this.newReload(false)
				this.isLoadingFilter = false // to be called when every promises (load/add etc..) are completed
			} catch (error) {
				console.error('err',error)
				this.isLoadingFilter = false
			}
		},
		async newReload (checkDaysBack = false) {
			this.reset()
			const daysback = this.editValues.days_back ? this.editValues.days_back : 0
			const promises = []
			for (const m of this.editValues.measures) {
				const measureIt = this.measureById(m)
				if (measureIt) {
					delete this.$globalData.results.evaluationStore.resultData[measureIt.id]
					if ((m === this.selected) || (this.selected === 'all')) {
						const cachekey = `${measureIt.id}_${daysback}`
						promises.push(
							this.getData(cachekey).then((result) => {
								if (result) {
									this.$globalData.results.evaluationStore.resultData[m] = result
									this.$globalData.results.evaluationStore.fullData[m] = result
									return this.fetchBenchmarkData(measureIt)
								} else {
									return this.loadData({ measure: measureIt, daysBack: this.editValues.days_back }).then((result) => {
										this.$globalData.results.evaluationStore.resultData[m] = result
										this.$globalData.results.evaluationStore.fullData[m] = result
										return this.addData(this.resultData, daysback)
									})
								}
							})
						)
					} else if (checkDaysBack) {
						const cachekey = `${measureIt.id}_${0}`
						promises.push(
							this.getData(cachekey).then((result) => {
								if (result) {
									this.$globalData.results.evaluationStore.resultData[m] = result
									this.$globalData.results.evaluationStore.fullData[m] = result
									return this.fetchBenchmarkData(measureIt)
								} else {
									return this.loadData({ measure: measureIt, daysback: '' }).then((result) => {
										this.$globalData.results.evaluationStore.resultData[m] = result
										this.$globalData.results.evaluationStore.fullData[m] = result
									})
								}
							}))
					}
				}
			}

			await Promise.all(promises) // Await all promises before proceeding
			if (this.exceedData !== true) {
				await this.updateGlobalVar(daysback)
			}
		},
		async onElementChanged (selectedValue) {
			if (this.editValues.days_back) {
				setTimeout(async () => {
					if (selectedValue) {
						this.selected = selectedValue
					}
					const daysback = this.editValues.days_back ? this.editValues.days_back : 0
					this.reset()
					for (const m of this.editValues.measures) {
						const measureIt = this.measureById(m)
						delete this.$globalData.results.evaluationStore.resultData[measureIt.id]
						if ((m === selectedValue) || (selectedValue === 'all')) {
							const cachekey = `${measureIt.id}_${daysback}`
							var result = await this.getData(cachekey)
							if (result) {
								this.$globalData.results.evaluationStore.resultData[m] = result
								this.$globalData.results.evaluationStore.fullData[m] = result
								await this.fetchBenchmarkData(measureIt)
							} else {
								this.loadData({ measure: measureIt, daysBack: this.editValues.days_back }).then((result) => {
									this.$globalData.results.evaluationStore.resultData[m] = result
									this.$globalData.results.evaluationStore.fullData[m] = result
								})
								await this.addData(this.resultData, daysback)
							}
						} else {
							const cachekey = `${measureIt.id}_${0}`
							let result0 = await this.getData(cachekey)
							if (result) {
								await this.fetchBenchmarkData(measureIt)
								this.$globalData.results.evaluationStore.resultData[m] = result0
								this.$globalData.results.evaluationStore.fullData[m] = result0
							} else {
								this.loadData({ measure: measureIt, daysback: '' }).then((result) => {
									this.$globalData.results.evaluationStore.resultData[m] = result
									this.$globalData.results.evaluationStore.fullData[m] = result
								})
							}
						}
					}
					if (this.exceedData !== true) {
						await this.updateGlobalVar(daysback)
					}
				}, 500)
			}
		},
		async updateGlobalVar (daysback) {
			let fromGlobal = this.$globalData.results.evaluationStore.fullData
			await this.addData(fromGlobal, daysback)
			for (const m of this.editValues.measures) {
				const measure = this.measureById(m)
				if (measure !== undefined) {
					let saveToVariable = await this.filterDataByGlobalFilter({ measure: measure, globalFilters: this.editValues.filters, fromGlobal: fromGlobal})
					this.$globalData.results.evaluationStore.resultData[m] = saveToVariable[m]
				}
			}
		},
		updateScrollTabbar () {
			const element = this.$refs.tabbar
			if (element) {
				this.showRightScroll = element.scrollLeft + 100 < (element.scrollWidth - element.clientWidth) && element.scrollWidth > element.clientWidth
				this.showLeftScroll = element.scrollLeft > 0 && element.scrollWidth > element.clientWidth
			}
		},
		scrollLeft () {
			const element = this.$refs.tabbar
			element.scrollTo({
				top: 0,
				left: element.scrollLeft - 250,
				behavior: 'smooth'
			})
		},
		scrollRight () {
			const element = this.$refs.tabbar
			element.scrollTo({
				top: 0,
				left: element.scrollLeft + 250,
				behavior: 'smooth'
			})
		},
		measureSelected () {
			// check here for content type all measure must have the same content type
			this.updateEditValues()
		},
		onMoveChart (chart) {
			this.moveChart = chart
			this.tabMoverVisible = true
		},
		onTabSelected (tabIndex) {
			let index = this.charts.findIndex(c => c.i === this.moveChart.i)

			let y = 0
			const tabCharts = this.charts.filter(chart => chart.tabIndex === tabIndex)
			if (tabCharts.length) {
				y = Math.max(...tabCharts.map(c => c.y))
			}
			this.moveChart.tabIndex = tabIndex
			this.moveChart.y = y
			this.$set(this.charts, index, this.moveChart)
			this.editChart = null
			this.moveChart = null
			this.tabMoverVisible = false
			this.updateEditValues()
		},
		addTab () {
			this.tabs.push({ name: `Tab ${this.tabs.length + 1}`, name_fr: '', name_it: '' })
			this.editValues.tabs = this.tabs
			this.$store.commit('setEditValues', this.editValues)
			this.currentTab = this.tabs.length - 1
			this.currentEditTab = -1
			this.$nextTick(() => {
				const element = this.$refs.tabbar
				element.scrollLeft = element.scrollWidth - element.clientWidth
				this.updateScrollTabbar()
			})
		},
		updateTabs (evt) {
			this.editValues.tabs = this.tabs
			this.charts.forEach((chart, index) => {
				// Move newIndexChart
				let cloned = clone(chart)
				if (cloned.tabIndex === evt.moved.oldIndex) {
					cloned.tabIndex = evt.moved.newIndex
				} else if (evt.moved.oldIndex < cloned.tabIndex && evt.moved.newIndex >= cloned.tabIndex) {
					cloned.tabIndex = cloned.tabIndex - 1
				} else if (evt.moved.oldIndex > cloned.tabIndex && evt.moved.newIndex <= cloned.tabIndex) {
					cloned.tabIndex = cloned.tabIndex + 1
				}
				this.$set(this.charts, index, cloned)
			})
			this.currentTab = evt.moved.newIndex
			this.updateEditValues()
		},
		removeTab (index) {
			const params = {
				title: 'Löschen',
				text: 'Möchten Sie wirklich löschen? (Alle Charts in diesem Tab werden ebenfalls gelöscht)',
				type: 'warning',
				onConfirm: () => {
					this.tabs.splice(index, 1)
					this.editValues.tabs = this.tabs

					// Remove Charts from this Tab
					this.charts = this.charts.filter(chart => chart.tabIndex !== index)
					// Fix Charts tabindex > deletedTab
					this.charts.filter(chart => chart.tabIndex > index).forEach((chart) => {
						chart.tabIndex = chart.tabIndex - 1
					})
					this.updateEditValues()
					if (this.currentTab === index) {
						if (this.currentTab >= this.tabs.length) {
							this.currentTab = this.tabs.length - 1
						}
					}
					this.currentEditTab = -1
					this.$nextTick(() => {
						this.updateScrollTabbar()
					})
				}
			}
			this.$confirmModal.show(params)
		},
		tabClick (e, index) {
			if (this.currentEditTab === index) {
				let cell = e.target
				let range, selection
				if (document.body.createTextRange) {
					range = document.body.createTextRange()
					range.moveToElementText(cell)
					range.select()
				} else if (window.getSelection) {
					selection = window.getSelection()
					range = document.createRange()
					range.selectNodeContents(cell)
					selection.removeAllRanges()
					selection.addRange(range)
				}
			}
			if (this.currentTab === index) {
				this.currentEditTab = index
			} else {
				this.currentEditTab = -1
			}
			this.currentTab = index
		},
		onPasteTab (e) {
			const paste = (e.clipboardData || window.clipboardData).getData('text/plain')
			e.target.textContent = paste
		},
		checkEndEdit (e) {
			let keycode = e.charCode || e.keyCode
			if (keycode === 13) {
				this.currentEditTab = -1
			}
		},
		updateTab (e) {
			const key = this.currentEvaluationLang == null || this.currentEvaluationLang === 'de' ? 'name' : `name_${this.currentEvaluationLang}`
			this.tabs[this.currentEditTab][key] = e.target.innerText.trim()
			this.$set(this.editValues, 'tabs', this.tabs)
			this.$store.commit('setEditValues', this.editValues)
		},
		onMeasureChanged (measure) {
			this.currMeasure = measure
		},
		onFilterChanged (chart) {
			this.editChart = chart
			if (this.exceedData !== true) {
				let fromGlobal = this.$globalData.results.evaluationStore.resultData
				this.loadDataByFilter({ chartFilter: chart.filters, chartId: chart.i, force: true, fromGlobal: fromGlobal })
			}
		},
		onUpdateChart (chart) {
			let index = this.charts.findIndex(c => c.i === chart.i)
			this.$set(this.charts, index, chart)
			this.editChart = null
			document.body.style.overflow = 'visible'
			this.updateEditValues()
		},
		onUpdateUserChart (userChart) {
			const newEditUserCharts = []
			const bindThis = this
			// filter by id get all charts
			for (let i = 0; i < this.editUserCharts.charts_info.length; i++) {
				const filteredChart = this.editValues.charts.filter(chart => chart.i === this.editUserCharts.charts_info[i].i)[0]
				const makeItUserChart = {
					...bindThis.editUserCharts.charts_info[i],
					languageTabTitle: filteredChart.languageTabTitle,
					languageTabSubtitle: filteredChart.languageTabSubtitle
				}
				newEditUserCharts.push(makeItUserChart)
			}
			const transformArrayToObject = {
				...userChart,
				charts_info: newEditUserCharts
			}
			this.$store.commit('setUserCharts', transformArrayToObject)
		},
		onEditChart (chart) {
			document.body.style.overflow = 'hidden'
			this.editChart = chart
		},
		onCloneChart (chart) {
			let cloned = clone(chart)
			let id = Math.max(...this.charts.map(c => c.i))
			let y = Math.max(...this.charts.map(c => c.y))
			this.$set(cloned, 'i', id + 1)
			this.$set(cloned, 'y', y)
			this.charts.push(cloned)
			this.updateEditValues()
		},
		movedEvent (i, newX, newY) {
			let chart = this.charts.find(c => c.i === i)
			this.$set(chart, 'x', newX)
			this.$set(chart, 'y', newY)
			this.updateEditValues()
		},
		resizeEvent () {
			this.resizing = true
		},
		resizedEvent (i, newH, newW) {
			this.resizing = false
			let chart = this.charts.find(c => c.i === i)
			this.$set(chart, 'w', newW)
			this.$set(chart, 'h', newH)
			this.updateEditValues()
		},
		allSeriesDefaultGrouped () {
			let result = {}
			for (const m of this.editValues.measures) {
				const measure = this.measureById(m)
				result[m] = { measure: { id: m, name: measure.name }, data: this.seriesDefaultGrouped(measure.id) }
			}
			return result
		},
		updateSeriesDimensions () {
			let result = []
			for (const dim of this.editValues.dimensions) {
				const keys = []
				const currentTitle = this.translate(dim, 'title', this.getLanguage)
				for (const index in dim.data) {
					const data = dim.data[index]
					if (this.seriesDefaultWithoutText(data.measure.id)) {
						var series = this.seriesDefaultWithoutText(data.measure.id).find(v => v.key === data.key)
					}
					keys.push(series)
				}
				result.push({ id: dim.id, title: currentTitle, keys: keys })
			}
			this.seriesDimensions = result
		},
		back () {
			this.$router.replace('/')
		},
		updateCurrentChart (index) {
			this.currentChartIndex = index
		},
		flushQueue () {
			return new Promise((resolve) => setTimeout(resolve, 500))
		},
		getHeightDimension () {
			let h = 0
			this.$refs['grid-layout'].$el.children.forEach(c => {
				let rect = c.getBoundingClientRect()
				if (rect.y + rect.height > h) {
					h = rect.y + rect.height
				}
			})
			return h - 240
		},
		async exportPdf  () {
			let oldTab = this.currentTab
			this.print = true
			this.currentTab = 0
			let h = this.getHeightDimension()
			let w = this.$refs['grid-layout'].$el.getBoundingClientRect().width + 10
			let doc = jsPDF({
				orientation: h > w ? 'p' : 'l',
				unit: 'px',
				format: [w * 2, h * 2],
				hotfixes: ['px_scaling']
			})
			for (const ix in this.tabs) {
				await this.$nextTick()
				await this.flushQueue()

				let canvas = await html2canvas(this.$refs['grid-layout'].$el, {
					allowTaint: true,
					scale: 2,
					height: h,
					width: w,
					ignoreElements: (element) => {
						if (element.classList.contains('apexcharts-toolbar')) { return true }
						if (element.classList.contains('vue-resizable-handle')) { return true }
					}
				})
				let imgData = canvas.toDataURL('image/jpeg')
				doc.addImage(imgData, 'JPEG', 10, 10)
				if (this.tabs.length - 1 > ix) {
					this.currentTab += 1
					await this.$nextTick()
					await this.flushQueue()
					h = this.getHeightDimension()
					w = this.$refs['grid-layout'].$el.getBoundingClientRect().width + 10
					doc.addPage(
						[w * 2, h * 2],
						w > h ? 'l' : 'p'
					)
				}
			}
			doc.save(`${this.item.name}.pdf`)
			this.print = false
			this.currentTab = oldTab
		},
		addTextBlock () {
			let id = 0
			let y = 0
			if (this.charts.length) {
				id = Math.max(...this.charts.map(c => c.i))
			}
			if (this.chartsForCurrentTab.length) {
				y = Math.max(...this.chartsForCurrentTab.map(c => c.y))
			}
			this.charts.push({
				type: 'text',
				text: 'Überschrift',
				text_fr: '',
				text_it: '',
				fontSize: 1.0,
				x: 0,
				y: y,
				w: 6,
				h: 1,
				i: id + 1,
				tabIndex: this.currentTab
			})
		},
		addEvaluation () {
			let id = 0
			let y = 0
			if (this.charts.length) {
				id = Math.max(...this.charts.map(c => c.i))
			}
			if (this.chartsForCurrentTab.length) {
				y = Math.max(...this.chartsForCurrentTab.map(c => c.y))
			}
			this.charts.push({
				data: [],
				slices: [],
				splits: [],
				groups: [],
				x: 0,
				y: y,
				w: 12,
				h: 10,
				i: id + 1,
				tabIndex: this.currentTab,
				languageTabSubtitle: this.currentEvaluationLang,
				languageTabTitle: this.currentEvaluationLang
			})
			this.editChart = this.charts[this.charts.length - 1]
			document.body.style.overflow = 'hidden'
		},
		onCancelEditChart (chart) {
			if (this.editchart) {
				this.removeEvaluation(chart)
			}
			if (this.exceedData !== true) {
				let fromGlobal = this.$globalData.results.evaluationStore.resultData
				this.loadDataByFilter({ chartFilter: [], chartId: chart.i, force: true, fromGlobal: fromGlobal })
			}
			this.editChart = null
			document.body.style.overflow = 'visible'
		},
		removeEvaluation (chart) {
			let index = this.charts.findIndex(c => c.i === chart.i)
			this.charts.splice(index, 1)

			this.editValues.charts.splice(index, 1)
			this.$store.commit('setEditValues', this.editValues)

			this.editChart = null
			document.body.style.overflow = 'visible'
		},
		updateDimensions (dimensions) {
			this.editValues.dimensions = dimensions
			this.$store.commit('setEditValues', this.editValues)
			this.updateSeriesDimensions()
		},
		updateEditValues () {
			let positiveNumberInput = document.getElementById('daysbackInput')
			if (positiveNumberInput && !positiveNumberInput.checkValidity()) {
				this.editValues.days_back = 0
				return
			}
			this.editValues.days_back = parseInt(this.editValues.days_back) || null
			let charts = []
			this.charts.forEach(chart => {
				if (chart.type === 'text') {
					charts.push({
						type: 'text',
						text: chart.text,
						text_fr: chart.text_fr,
						text_it: chart.text_it,
						fontSize: chart.fontSize || 1.5,
						isBold: chart.isBold ?? false,
						isItalic: chart.isItalic || false,
						x: chart.x,
						y: chart.y,
						w: chart.w,
						h: chart.h,
						i: chart.i,
						tabIndex: chart.tabIndex
					})
				} else {
					const attr = chart.data?.reduce(function (acc, value) {
						// Wenn keys vorhanden sind dann ist es eine Dimension
						if (value.keys !== undefined) {
							acc.push({ dimensionId: value.id })
						} else {
							acc.push({ measureId: value.measure.id, key: value.key })
						}
						return acc
					}, [])

					const slices = chart.slices.map(d => { return { measure: d.measure.id, key: d.key } })
					const splits = chart.splits.map(d => { return { measure: d.measure.id, key: d.key } })
					const groups = chart.groups.map(d => { return { measure: d.measure.id, key: d.key } })

					charts.push({
						version: 4,
						addition: chart.addition,
						title: chart.title || '',
						...chart.title_fr && { title_fr: chart.title_fr },
						...chart.title_it && { title_it: chart.title_it },
						subtitle: chart.subtitle || '',
						...chart.subtitle_fr && { subtitle_fr: chart.subtitle_fr },
						...chart.subtitle_it && { subtitle_it: chart.subtitle_it },
						// languageTabTitle: chart.languageTabTitle || this.getLanguage,
						languageTabTitle: chart.languageTabTitle,
						// languageTabSubtitle: chart.languageTabSubtitle || this.getLanguage,
						languageTabSubtitle: chart.languageTabSubtitle,
						measures: chart.measures || [this.measure.id],
						type: chart.type || 'bar',
						viewSettings: chart.viewSettings,
						attributes: attr,
						slices: slices,
						splits: splits,
						groups: groups,
						x: chart.x,
						y: chart.y,
						w: chart.w,
						h: chart.h,
						i: chart.i,
						filters: chart.filters || [],
						tabIndex: chart.tabIndex,
						...chart.timelineAttribute && { timelineAttribute: chart.timelineAttribute }
					})
				}
			})
			this.editValues.charts = charts
			// here in the chart 1 it's my graphic with all values in attrubues and I can see how it changed when i delete questions on likert chart
			this.$store.commit('setEditValues', this.editValues)
		}
	},
	created() {
		this.debouncedReload = debounce(async () => {
			await this.newReload(true)
			this.updateEditValues()
		}, 500)
	},
	onBeforeUnmount () {
		this.debouncedReload.cancel()
	},
	watch: {
		daysBack: {
			async handler (val, old) {
				let positiveNumberInput = document.getElementById('daysbackInput')
				if (positiveNumberInput && !positiveNumberInput.checkValidity()) {
					alert('number must be between 0 and 1096')
				} else {
					await this.debouncedReload(val, old)
				}
			}
		}
	},
	async mounted () {
		this.reset()
		this.openDatabase()
		await this.$store.dispatch('results/measures/load', { all: true })

		this.startLoading()
		document.body.style.overflow = 'hidden'
		await this.$store.dispatch('accounts/appPermissions/load', { all: true, query: { user: this.user.id } })
		// Check if migrate tab to object with  names
		if (this.editValues.tabs.length && this.editValues.tabs[0].name === undefined) {
			this.tabs = [...this.editValues.tabs.map(t => { return { name: t } })]
		} else {
			this.tabs = [...this.editValues.tabs]
		}
		if (this.tabs.length === 0) {
			this.addTab()
		}

		ApiClient.get(`results/measures/${this.editValues.measures[0]}/objects/`).then(response => {
			this.allViewAccessObjects = response.data.data
		})

		await this.cleanupOldData()
		this.currentEvaluationLang = this.getLanguage
		const daysback = this.editValues.days_back ? this.editValues.days_back : 0

		// Load Data for measures
		for (const m of this.editValues.measures) {
			const measure = this.measureById(m)
			const cachekey = `${m}_${daysback}`
			if (measure !== undefined) {
				let result = await this.getData(cachekey)
				let storeToGlobal = {}
				let storeToGlobalDef = {}

				if (result) {
					storeToGlobal = result
					await this.fetchBenchmarkData(measure)
					this.$globalData.results.evaluationStore.fullData[m] = result
					this.$globalData.results.evaluationStore.resultData[m] = result
				} else {
					storeToGlobal = await this.loadData({ measure: measure, daysBack: this.editValues.days_back })
					storeToGlobalDef = this.exceedData != true? storeToGlobal: {}

					this.$globalData.results.evaluationStore.fullData[m] = storeToGlobalDef
					this.$globalData.results.evaluationStore.resultData[m] = storeToGlobalDef
				}
				this.loadSeriesDefault({measure:measure,storeToGlobal:storeToGlobal})
			}
		}

		if (this.exceedData !== true) {
			await this.updateGlobalVar(daysback) }

		const timelineAttribute = this.getTimeLineAttribute
		if (this.editValues.charts !== undefined) {
			this.charts = []
			let answerKeys = {}
			let id = 0

			for (const chart of this.editValues.charts) {
				var config = null
				if (chart.type === 'text') {
					const item = {
						type: chart.type,
						text: chart.text,
						text_fr: chart.text_fr,
						text_it: chart.text_it,
						fontSize: chart.fontSize || 1.5,
						isBold: chart.isBold || false,
						isItalic: chart.isItalic || false,
						x: chart.x !== undefined ? chart.x : 0,
						y: chart.y !== undefined ? chart.y : id * 10,
						w: chart.w !== undefined ? chart.w : 6,
						h: chart.h !== undefined ? chart.h : 10,
						i: chart.i !== undefined ? chart.i : id,
						tabIndex: chart.tabIndex !== undefined ? chart.tabIndex : 0
					}
					this.charts.push(item)
				} else {
					var data = []
					if (chart.attributes) {
						if (chart.version < 4) {
							Object.keys(chart.attributes).forEach(measureId => {
								chart.attributes[measureId].forEach(at => {
									const tmp = this.seriesDefault[measureId].find(s => s.key === at)
									if (tmp) {
										data.push(tmp)
										config = { ...config, ...tmp.config }
										answerKeys = { ...answerKeys, ...tmp.answerKeys }
									}
								})
							})
						} else {
							// Load new with dims

							chart.attributes.forEach(attr => {
								if (attr.dimensionId !== undefined) {
									let dim = this.editValues.dimensions.find(d => d.id === attr.dimensionId)

									if (dim !== undefined) {
										let keys = []
										dim.data.forEach(d => {
											if (this.seriesDefault[d.measure.id]) {
												const tmp = this.seriesDefault[d.measure.id].find(s => s.key === d.key)
												if (tmp) {
													keys.push(tmp)
													config = { ...config, ...tmp.config }
													answerKeys = { ...answerKeys, ...tmp.answerKeys }
												}
											}
										})
										data.push({ id: dim.id, title: dim.title, keys: keys })
									}
								} else {
									if (this.seriesDefault[attr.measureId]) {
										const tmp = this.seriesDefault[attr.measureId].find(s => s.key === attr.key)
										if (tmp) {
											data.push(tmp)
											config = { ...config, ...tmp.config }
											answerKeys = { ...answerKeys, ...tmp.answerKeys }
										}
									}
								}
							})
						}
					}

					let slices = []
					var splits = []
					if (chart.splits) {
						chart.splits.forEach(at => {
							// fix for old version where no measure key was saved
							const key = at.key || at
							const mId = at.measure || this.measure.id
							if (this.seriesDefault[mId]) {
								const tmp = this.seriesDefault[mId].find(s => s.key === key)
								if (tmp) {
									splits.push(tmp)
								}
							}
						})
					}

					var groups = []
					if (chart.groups) {
						chart.groups.forEach(at => {
							// fix for old version where no measure key was saved
							const key = at.key || at
							const mId = at.measure || this.measure.id
							if (this.seriesDefault[mId]) {
								const tmp = this.seriesDefault[mId].find(s => s.key === key)
								if (tmp) {
									groups.push(tmp)
								}
							}
						})
					}
					// migrate view_settings to version 3 delte if all charts are version 3
					let viewSetting = []
					let viewSettings = []
					if (chart.version < 3) {
						if (chart.showBenchmark) {
							viewSettings.push('benchmark')
						}
						if (chart.showErrorBars) {
							viewSettings.push('error_bars')
						}
						if (chart.horizontal) {
							viewSettings.push('horizontal')
						}
						if (chart.colorMinus10) {
							viewSettings.push('color_less_10')
						}
						if (chart.datalabels) {
							viewSettings.push('datalabels')
						}
					} else {
						viewSettings = chart.viewSettings
					}
					const item = {
						addition: chart.addition,
						version: chart.version || 4,
						title: chart.title || '',
						...chart.title_fr && { title_fr: chart.title_fr },
						...chart.title_it && { title_it: chart.title_it },
						subtitle: chart.subtitle || '',
						...chart.subtitle_fr && { subtitle_fr: chart.subtitle_fr },
						...chart.subtitle_it && { subtitle_it: chart.subtitle_it },
						languageTabTitle: chart.languageTabTitle || 'de',
						languageTabSubtitle: chart.languageTabSubtitle || 'de',
						measures: chart.measures || this.editValues.measures || [this.editValues.measure.id],
						type: chart.type || 'bar',
						viewSetting: viewSetting,
						viewSettings: viewSettings,
						data: data,
						slices: slices,
						splits: splits,
						groups: groups,
						config: config && Object.keys(config).length ? config : undefined,
						answerKeys: answerKeys,
						x: chart.x !== undefined ? chart.x : 0,
						y: chart.y !== undefined ? chart.y : id * 10,
						w: chart.w !== undefined ? chart.w : 6,
						h: chart.h !== undefined ? chart.h : 10,
						i: chart.i !== undefined ? chart.i : id,
						filters: chart.filters,
						tabIndex: chart.tabIndex !== undefined ? chart.tabIndex : 0,
						...chart.timelineAttribute && { timelineAttribute: chart.timelineAttribute },
						dimensions: chart.dimensions || []
					}
					if (chart.timelineAttribute) {
						item.timelineAttribute = chart.timelineAttribute
					} else if (timelineAttribute) {
						item.timelineAttribute = timelineAttribute.name_new || timelineAttribute.name
					}
					// Check if has no likert config and type is likert
					const checkArray = ['bar', 'stacked', 'stacked-full', 'boxPlot', 'pyramid', 'tableChart']
					if (item.config === undefined && !checkArray.includes(item.type)) {
						item.type = 'bar'
					}

					// centralized sorting (single source of truth)
					for (const i of ['data', 'splits', 'groups']) {
						item[i].forEach(serie => {
							if (serie.data) {
								const areAllNumbers = serie.data.every((item) => typeof item.value === 'number')
								const areAllStrings = serie.data.every((item) => typeof item.value === 'string')
								if (areAllStrings) {
									serie.data.sort((a, b) => a.value.localeCompare(b.value))
								}
								if (areAllNumbers) {
									serie.data.sort((a, b) => a.value - b.value)
								} else {
									serie.data.sort()
								}
							} else {
								serie.keys.forEach(dim => {
									const areAllNumbers = dim.data.every((item) => typeof item.value === 'number')
									const areAllStrings = dim.data.every((item) => typeof item.value === 'string')
									if (areAllStrings) {
										dim.data.sort((a, b) => a.value.localeCompare(b.value))
									}
									if (areAllNumbers) {
										dim.data.sort((a, b) => a.value - b.value)
									} else {
										dim.data.sort()
									}
								})
							}
						})
					}

					if (chart.attributes) {
						this.charts.push(item)
					}
				}
				id += 1
			}
		}

		if (this.charts.length === 0 && !this.isAdminOrStaff) {
			this.currentMainTab = 3
		}
		this.updateSeriesDimensions()
		this.updateScrollTabbar()
		this.measureGlobalFilter = this.editValues.measures[0]
		setTimeout(() => {
			this.endLoading()
			document.body.style.overflow = 'visible'
		}, 400)
		if (this.isInEvaluationOnly) {
			this.fetchTooltipData('evaluations')
		}
	}
}
</script>
