<template>
  <div class="z-50">
    <div class="absolute right-3 top-2 z-50" v-click-outside="close">

      <div @click="isOpen = !isOpen" class="cursor-pointer">
        <svg class="h-6 w-6"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24"
             stroke="currentColor"
             aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </div>
      <div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-52  rounded-md shadow-lg py-1 bg-white dark:bg-neutral-700 ring-1 ring-black dark:ring-neutral-500 ring-opacity-5 focus:outline-none max-h-80 overflow-y-auto">

        <div v-for="c in benchmarkLabels"
             :key="c.index"
             class="px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer flex items-center"
             @click="updateChartView(c.name,c.index)">
          <span class="w-5 fill-current">
            <svg v-if="viewSetting?.length && radioIndex===c.index"
                 class="h-4 w-4 "
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24"
                 fill-rule="evenodd"
                 clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>
          </span>
          <span>{{c.label}}</span>
        </div>
        <div v-if="benchmarkLabels.length" class="border-t-2 dark:border-neutral-500"></div>

        <div v-for="c in viewTypes"
             :key="c.name"
             class="px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer flex items-center"
             @click="updateChartViews(c.name)">
          <span class="w-5 fill-current">
            <svg v-if="viewSettings?.includes(c.name)"
                 class="h-4 w-4 "
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24"
                 fill-rule="evenodd"
                 clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>
          </span>
          <span>{{c.label}}</span>
        </div>
        <div v-if="viewTypes.length" class="border-t-2 dark:border-neutral-500"></div>

        <div v-for="c in chartTypes"
             :key="c.name"
             class="flex items-center px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer"
             @click="changeChart(c.name)">
          <!-- <pre>{{ c }}</pre> -->
          <span class="w-5">
            <svg v-if="chart.type === c.name"
                 class="h-4 w-4 fill-current"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24"
                 fill-rule="evenodd"
                 clip-rule="evenodd"><path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>
          </span>
          <span>{{c.label}}</span>
        </div>
        <template v-if="!isPreview">
          <div class="border-t-2 dark:border-neutral-500"></div>
          <div v-if="!isTable" @click="download('svg')" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('download-svg') }}</div>
          <div v-if="!isTable" @click="download('png')" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('download-png') }}</div>
          <div v-if="!isTable" @click="download('apex')" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('download-csv') }}</div>
          <div v-if="isTable" @click="download('csv')" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('download-csv') }}</div>
          <template v-if="isAdminOrStaff">
            <div class="border-t-2 dark:border-neutral-500"></div>
            <div v-if="hasTabs" @click="moveChart" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('move-to-tab') }}</div>
            <div v-if="isStaff||chart.type!='pyramid'" @click="editChart" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('edit') }}</div>
            <div @click="cloneChart" class="block px-4 py-1 text-sm text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-600 cursor-pointer">{{ $t('duplicate') }}</div>
            <div @click="deleteChart" class="block px-4 py-1 text-sm text-red-700 dark:text-red-400 hover:bg-red-100 dark:hover:bg-red-800 dark:hover:text-red-100 cursor-pointer border-t-2 dark:border-neutral-500">{{ $t('delete') }}</div>
          </template>
        </template>
      </div>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ApiClient from '@/assets/js/ApiClient.js'

export default {
	props: {
		chart: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			isOpen: false,
			radioIndex: 0,
			benchmarkLabels: [],
			viewTypeDef: [
				{ label: this.$t('benchmark'), name: 'benchmark', type: ['bar', 'stacked', 'stacked-full', 'boxPlot', 'mean', 'pyramid'], visible: () => { return this.hasBenchmark } }
			],
			viewTypesDef: [
				{ label: this.$t('95-errorbars'), name: 'error_bars', types: ['mean'] },
				{ label: this.$t('horizonal'), name: 'horizontal', types: ['bar', 'stacked', 'stacked-full', 'boxPlot', 'tableChart'] },
				{ label: this.$t('calculate percents per column'), name: 'calculate_columns_percents', types: ['tableChart'] },
				{ label: this.$t('show-values'), name: 'datalabels', types: ['radar', 'mean', 'bar', 'stacked', 'stacked-full'] },
				{ label: this.$t('stacked-bar-0-100'), name: 'stacked_likert', types: ['likert'] }
			],
			chartTypesDef: [
				{ label: 'Timeline', name: 'timeline', needsConfig: true },
				{ label: 'Spider Plot', name: 'radar', needsConfig: true },
				{ label: 'Mean Chart', name: 'mean', needsConfig: true, addSkala: () => { return this.hasSkala } },
				{ label: 'Likert Chart', name: 'likert', needsConfig: true, visible: () => { return !this.hasDimensions } },
				{ label: 'IKA Chart', name: 'ika', needsConfig: true, visible: () => { return this.isStaff } },
				{ label: 'Simple Bar', name: 'bar', needsConfig: false },
				{ label: 'Stacked Bar', name: 'stacked', needsConfig: false },
				{ label: 'Full Stacked Bar', name: 'stacked-full', needsConfig: false },
				{ label: 'Box Plot Chart', name: 'boxPlot', needsConfig: false },
				{ label: 'Pyramid Chart', name: 'pyramid', needsConfig: false, visible: () => { return this.feasibleChart && this.feasibleData } },
				{ label: 'Table Chart', name: 'tableChart', needsConfig: false, visible: () => { return this.exceedData != true } }
			],
			keysToKeep: ['data', 'groups', 'slices', 'splits'],
			chartsWithBenchmarks: ['bar', 'stacked', 'stacked-full', 'boxPlot', 'mean', 'pyramid']
		}
	},
	computed: {
		...mapGetters({
			user: 'user',
			isStaff: 'isStaff',
			hasBenchmark: 'results/evaluationStore/hasBenchmark',
			resultBenchmarkForMeasureId: 'results/evaluationStore/resultBenchmarkForMeasureId',
			currentEvaluation: 'results/evaluations/detailItem',
			exceedData: 'results/evaluationStore/exceedData'
		}),
		feasibleChart () {
			// currently allow switch to other graphs, can be discussed in the group
			// const allowedSelection = ['Percentage', 'Count']
			return !this.chart.groups?.length/* && (!this.chart.addition || allowedSelection.includes(this.chart.addition)) */
		},
		feasibleData () {
			return this.chart.splits?.length && this.chart.splits[0].data.length === 2
		},
		hasSkala () {
			const arrayofObjects = this.chart.data
			const allObjectsMeetCondition = arrayofObjects.every(obj => obj.skala === true)
			return allObjectsMeetCondition
		},
		hasDimensions () {
			for (const data of this.chart.data) {
				if (data.keys !== undefined) {
					return true
				}
			}
			return false
		},
		chartTypes () {
			let def = this.chartTypesDef

			if (this.chart.timelineAttribute === undefined) {
				def = def.filter(c => c.name !== 'timeline')
			}
			// Can likert and mean
			if (this.chart.config === undefined) {
				def = def.filter(t => t.needsConfig === false || (t.addSkala !== undefined && t.addSkala() === true))
			}
			// Visible
			def = def.filter(c => c.visible === undefined || c.visible() === true)
			return def
		},
		viewType () {
			const type = this.viewTypeDef.filter(c => c.type.indexOf(this.chart.type) > -1)
			const type0 = type.filter(c => c.visible === undefined || c.visible() === true)
			return type0
		},
		viewTypes () {
			const types = this.viewTypesDef.filter(c => c.types.indexOf(this.chart.type) > -1)
			const types0 = types.filter(c => c.visible === undefined || c.visible() === true)
			return types0
		},
		viewSetting () {
			return this.chart.viewSetting
		},
		viewSettings () {
			return this.chart.viewSettings
		},
		addition () {
			return this.chart.addition
		},
		isPreview () {
			return this.$parent.$parent.isPreview
		},
		openMenu () {
			return this.$parent.$parent.openMenu
		},
		hasTabs () {
			return this.$parent.$parent.hasTabs === true
		},
		isAdminOrStaff () {
			return this.$parent.$parent.$parent.$parent.$parent.isAdminOrStaff
		},
		isTable () {
			return this.$parent?.tableData
		}
	},
	methods: {
		download (type) {
			const w = this.$parent.$refs?.chart?.chart
			const ctx = w?.ctx
			const parent = this.$parent
			const data = parent?.tableData

			switch (type) {
			case 'svg':
				ctx.exports.exportToSVG(ctx)
				break
			case 'png':
				ctx.exports.exportToPng(ctx)
				break
			case 'apex':
				ctx.exports.exportToCSV({
					series: this.$parent.series,
					columnDelimiter: ';'
				})
				break
			case 'csv':
				if (data) {
					this.download_csv_table(data)
				} else {
					ctx.exports.exportToCSV({
						series: this.$parent.series,
						columnDelimiter: ';'
					})
				}
				break
			}
		},
		download_csv_table (data) {
			const tableColumns = this.$parent.tableColumns
			const escapeValue = (value) => {
				if (typeof value === 'string' && value.includes(',')) {
					return `"${value}"`
				}
				return value
			}
			const headers = tableColumns.map(header => (header.title !== undefined && header.title !== null) ? escapeValue(header.title) : '')
			let rows = '\uFEFF'
			rows += headers.join(';') + '\n' // Initialize rows with headers
			// Determine the method based on the structure of the first entry
			const hasGroup = data[0] && (data[0]?.grouped_by)
			const hasDoubleSplit = data[0] && (data[0]?.split_by_twice)
			const hasDoubleGroup = data[0] && (data[0]?.grouped_by_twice)

			const safeSplit = (value) => {
				return typeof value === 'string' && value.includes('\n\n') ? value.split('\n\n') : [value, value]
			}
			if (hasGroup || hasDoubleSplit || hasDoubleGroup) {
				data.forEach((entry, i) => {
					const splitBy = escapeValue(entry.split_by ?? '')
					const splitByTwice = escapeValue(entry.split_by_twice ?? '')
					const groupBy = escapeValue(entry.grouped_by ?? '')
					const groupByTwice = escapeValue(entry.grouped_by_twice ?? '')

					let specialFuncName = 'Anzahl'

					if (data[i].row_name.includes('Mean')) {
						specialFuncName = 'Mean'
					} else if (data[i].row_name.includes('Median')) {
						specialFuncName = 'Median'
					} else if (data[i].row_name.includes('Sum')) {
						specialFuncName = 'Sum'
					} else if (data[i].row_name.includes('Unique')) {
						specialFuncName = 'Unique'
					} else if (data[i].row_name.includes('Percent')) {
						specialFuncName = 'Percent'
					} else if (data[i].row_name.includes('Anzahl')) {
						specialFuncName = 'Percent'
					}

					const prozentRow = [splitBy, splitByTwice, groupBy, groupByTwice, specialFuncName].filter(value => value !== undefined && value !== null && value !== '') // .map(escapeValue)
					const anzahlRow = [splitBy, splitByTwice, groupBy, groupByTwice, 'Anzahl'].filter(value => value !== undefined && value !== null && value !== '')// .map(escapeValue)

					if ((entry?.split_by === undefined) || (entry?.split_by === null) || (entry?.split_by === '' && entry?.grouped_by_twice === '')) {
						prozentRow.unshift(data[0].question)
						anzahlRow.unshift(data[0].question)
					}

					if (!data[i].row_name.includes('Mean') && !data[i].row_name.includes('Median') &&
              !data[i].row_name.includes('Unique') && !data[i].row_name.includes('Sum')) {
						const dataKeys = Object.keys(entry).filter(key => !isNaN(key)).sort((a, b) => a - b)
						dataKeys.forEach((key) => {
							const [percent, count] = safeSplit(entry[key])
							prozentRow.push(escapeValue(percent))
							anzahlRow.push(escapeValue(count))
						})
					}

					const [totalPercent, totalAnzahl] = safeSplit(entry.totalAnswers || '0')
					prozentRow.push(escapeValue(totalPercent))
					anzahlRow.push(escapeValue(totalAnzahl))

					rows += prozentRow.join(';') + '\n' + anzahlRow.join(';') + '\n'
				})
			} else {
				for (let i = 0; i < data.length; i += 2) {
					const prozentEntry = data[i]
					const anzahlEntry = data[i + 1] || {}

					const splitBy = escapeValue(prozentEntry.split_by ?? '')

					const specialFuncName = data[i].row_name

					const prozentRow = [splitBy, specialFuncName].filter(value => value !== undefined && value !== null && value !== '')// .map(escapeValue)
					const anzahlRow = [splitBy, 'Anzahl'].filter(value => value !== undefined && value !== null && value !== '')// .map(escapeValue)

					if (data[i]?.split_by === undefined || data[i]?.split_by === null || data[i]?.split_by === '') {
						prozentRow.unshift(data[0].question)
						anzahlRow.unshift(data[0].question)
					}

					if (data[i].row_name !== 'Mean' && data[i].row_name !== 'Median' &&
              data[i].row_name !== 'Unique' && data[i].row_name !== 'Sum') {
						const dataKeys = Object.keys(prozentEntry).filter(key => !isNaN(key)).sort((a, b) => a - b)
						dataKeys.forEach((key) => {
							const percent = prozentEntry[key] || '0'
							const count = anzahlEntry[key] || '0'

							prozentRow.push(escapeValue(percent))
							anzahlRow.push(escapeValue(count))
						})
					}

					const totalPercent = prozentEntry.totalAnswers || '0'
					const totalAnzahl = anzahlEntry.totalAnswers || '0'

					prozentRow.push(escapeValue(totalPercent))
					anzahlRow.push(escapeValue(totalAnzahl))

					rows += prozentRow.join(';') + '\n' + anzahlRow.join(';') + '\n'
				}
			}

			const csvContent = rows // rows.map((row) => row.join(',')).join('\n')

			const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
			const link = document.createElement('a')
			link.href = URL.createObjectURL(blob)
			link.setAttribute('download', 'output_table.csv')
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
		changeChart (type) {
			this.isOpen = false
			this.$parent.$emit('on-change-chart', type)
		},
		filterKeys (originalObj, keysToKeep) {
			return keysToKeep.reduce((obj, key) => {
				if (key in originalObj) {
					obj[key] = originalObj[key]
				}
				return obj
			}, {})
		},
		assignedBenchmarks (benchmark) {
			return benchmark.map(item => {
				const baseArray = item.filter.map(item => {
					const newObj = {}
					newObj[item.key] = item.value
					return newObj
				})
				const benchmark = item.benchmark_measures
				const measures = { measure: benchmark }
				return [...baseArray, measures]
			})
		},
		async updateChartView (name, index) {
			this.isOpen = false
			const assign = [name]
			const bool = this.radioIndex !== index
			if (bool || !this.viewSetting?.length) {
				this.radioIndex = index
				const currentMeasure = this.chart?.measures[0]
				this.$parent.$emit('on-update-chart-spinner-start')
				const filteredUrl = `results/compute-${this.chart.type}/`
				const filteredObj = this.filterKeys(this.chart, this.keysToKeep)
				const benchmark = this.resultBenchmarkForMeasureId(currentMeasure)
				const indexedBenchmark = benchmark.slice(index, index + 1)
				const keyToExtract = ['name_chart', 'name_chart_fr', 'name_chart_it']
				const compute = !this.addition || this.addition === 'Bitte wählen'
				filteredObj.addition = !compute ? this.chart.addition : 'Percentage'

				filteredObj.naming = indexedBenchmark.map(item => {
					const namedArray =
            Object.entries(item).filter(
            	key => keyToExtract.includes(key[0])
            )
					return Object.fromEntries(namedArray)
				})

				filteredObj.assign = this.assignedBenchmarks(indexedBenchmark)
				const result = await ApiClient.post(filteredUrl, filteredObj)
				assign.push(result.data)
				setTimeout(() =>
					this.$parent.$emit('on-update-chart-spinner-stop')
				)
			}
			this.$parent.$emit('on-update-chart-view', ...assign, bool)
		},
		async updateChartViews (name) {
			this.isOpen = false
			this.$parent.$emit('on-update-chart-views', name)
		},
		deleteChart () {
			this.isOpen = false
			this.$parent.$parent.$emit('on-delete-chart', this.chart)
		},
		cloneChart () {
			this.isOpen = false
			this.$parent.$parent.$emit('on-clone-chart', this.chart)
		},
		editChart () {
			this.isOpen = false
			this.$parent.$parent.$emit('on-edit-chart', this.chart)
		},
		moveChart () {
			this.isOpen = false
			this.$parent.$parent.$emit('on-move-chart', this.chart)
		},
		close () {
			// this.isOpen = false
		}
	},
	mounted () {
		if (this.hasBenchmark) {
			const language = this.user.language || 1
			const benchmark = this.resultBenchmarkForMeasureId(this.chart.measures[0])

			if (this.chartsWithBenchmarks.includes(this.chart.type)) {
				this.benchmarkLabels = benchmark.map((item, index) => {
					const switcher = [item.name_chart, item.name_chart_fr, item.name_chart_it]
					return { label: switcher[language - 1], name: 'benchmark', index: index }
				})
			}
		}
		if (this.openMenu) {
			this.isOpen = true
		}
	}
}
</script>
